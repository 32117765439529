import type React from 'react';

import { toTheDay } from '../../../../lib/locale-date-strings.ts';

interface GameMetaProps {
  publishedAt: Date;
}

export const GameMeta: React.FC<GameMetaProps> = ({ publishedAt }) => {
  return (
    <div className="text-xs italic opacity-70">
      Published {toTheDay(publishedAt)}
    </div>
  );
};
