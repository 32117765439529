import * as Sentry from '@sentry/astro';

import type { SortGame } from '../../game/sort/game.ts';

export const TrackEvents = {
  gameSolved: (game: SortGame, guessCount: number) => {
    fetch('/api/analytics/puzzle-solve', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        puzzle_id: game.id,
        guess_count: guessCount,
      }),
    });
    Sentry.metrics.increment('puzzle_solved', 1, {
      tags: { game_id: game.id, puzzle_id: game.id, guessCount: guessCount },
    });
  },
  resultCopied: (game: SortGame) => {
    Sentry.metrics.increment('result_copied', 1, {
      tags: { game_id: game.id, puzzle_id: game.id },
    });
  },
  resultShareClicked: (game: SortGame) => {
    Sentry.metrics.increment('result_share_clicked', 1, {
      tags: { game_id: game.id, puzzle_id: game.id },
    });
  },
  resultShared: (game: SortGame) => {
    Sentry.metrics.increment('result_shared', 1, {
      tags: { game_id: game.id, puzzle_id: game.id },
    });
  },
};
