import { ErrorBoundary } from 'react-error-boundary';

import type { SortGame } from '../../../game/sort/game.ts';
import { ClientEntry } from '../components/ClientEntry.tsx';
import { HorizontalLine } from '../components/design/HorizontalLine.tsx';
import { SortGamePlayer } from '../components/game/sort/SortGamePlayer.tsx';

export const Home = ({ game }: { game: SortGame }) => {
  return (
    <ClientEntry>
      <ErrorBoundary fallback={null}>
        <SaturdayAlert />
      </ErrorBoundary>
      <SortGamePlayer game={game} />
    </ClientEntry>
  );
};

const SaturdayAlert = () => {
  const now = new Date();
  now.setHours(now.getHours());

  const later = new Date();
  later.setHours(later.getHours() + 4);

  if (now.getDay() === 6 || later.getDay() === 6) {
    return (
      <div className="mx-auto mb-6 w-full max-w-[40rem] px-2">
        <details className="group rounded bg-rose-800 p-4">
          <summary className="hide-summary-marker flex list-none text-lg font-semibold after:ml-auto after:content-['▶'] after:group-open:content-['▼'] ">
            🤔 Looking for today's game?
          </summary>
          <div className="mt-3 text-gray-200">
            To give us some extra time to work on making bigger improvements to
            Disorderly, we'll no longer be posting new games on Saturdays.
          </div>
          <HorizontalLine />
          <div className="text-gray-200">
            In the meantime, you can check out yesterday's question below, or
            head over to our{' '}
            <a className="font-bold underline" href="/archive">
              Archive
            </a>{' '}
            to play any of the 200+ games you may have missed!
          </div>
        </details>
      </div>
    );
  }
  return null;
};
