import {
  rectSortingStrategy,
  rectSwappingStrategy,
  type SortingStrategy,
} from '@dnd-kit/sortable';

import type { OptionKey } from '../../../../../game/sort/game.ts';
import { settings } from '../../../settings/settings.ts';
import { useGamePlayContext } from './GamePlayContext.ts';

interface SortItemBehavior {
  useTransform: boolean;
  optionBackgroundColor?: string | undefined;
}

interface SortStrategy {
  dndkitStrategy: SortingStrategy;
  getItemBehavior(
    optionKey: OptionKey,
    isActive: boolean,
    isOver: boolean
  ): SortItemBehavior;
  handleSort(
    items: OptionKey[],
    activeId: OptionKey,
    overId: OptionKey | null
  ): OptionKey[];
}

const useDefaultSortStrategy = (): SortStrategy => {
  const { isOptionLocked } = useGamePlayContext();

  return {
    dndkitStrategy: rectSortingStrategy,
    getItemBehavior: () => {
      return {
        useTransform: true,
      };
    },
    handleSort(
      items: OptionKey[],
      activeId: string,
      overId: string
    ): OptionKey[] {
      if (overId && activeId !== overId) {
        const overIndex = items.findIndex((optionKey) => optionKey === overId);

        // init new array with same length as items
        const newItems = Array(items.length).fill(null);

        newItems[overIndex] = activeId;
        items.forEach((optionKey, index) => {
          if (isOptionLocked(optionKey)) {
            newItems[index] = optionKey;
          }
        });
        items.forEach((optionKey) => {
          if (activeId !== optionKey && !isOptionLocked(optionKey)) {
            const index = newItems.findIndex((item) => item === null);
            newItems[index] = optionKey;
          }
        });
        return newItems;
      }
      return items;
    },
  };
};

const useSwapSortStrategy = (): SortStrategy => {
  const { isOptionLocked } = useGamePlayContext();

  return {
    dndkitStrategy: rectSwappingStrategy,
    getItemBehavior: (optionKey, _, isOver) => {
      return {
        useTransform: false,
        optionBackgroundColor:
          isOver && !isOptionLocked(optionKey) ? '#5c5c5c' : undefined,
      };
    },
    handleSort(
      items: OptionKey[],
      activeId: OptionKey,
      overId: OptionKey | null
    ): OptionKey[] {
      if (overId && activeId !== overId && !isOptionLocked(overId as string)) {
        const newItems = [...items];
        const oldIndex = items.findIndex((optionKey) => activeId === optionKey);
        const newIndex = items.findIndex((optionKey) => overId === optionKey);
        const temp = newItems[newIndex];
        newItems[newIndex] = items[oldIndex];
        newItems[oldIndex] = temp;
        return newItems;
      }
      return items;
    },
  };
};
export const useSortStrategy = (): SortStrategy => {
  const defaultStrategy = useDefaultSortStrategy();
  const swapStrategy = useSwapSortStrategy();

  const sortingMode = settings.getSortingMode();
  switch (sortingMode) {
    case 'default':
      return defaultStrategy;
    case 'swap':
      return swapStrategy;
    default:
      throw new Error(`Invalid sorting mode: ${sortingMode}`);
  }
};
