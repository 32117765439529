import React from 'react';

import type { SortGame } from '../../../../../game/sort/game.ts';
import {
  getLatestGuess,
  gradeGuess,
  isGuessCorrect,
  type Submission,
} from '../../../../../game/sort/play.ts';

interface GamePlayContextValue {
  game: SortGame;
  submissions: Submission[];
  draftSubmission: Submission;
  initialOptionPositions: Record<string, number>;
}

export const GamePlayContext = React.createContext<GamePlayContextValue | null>(
  null
);

export const useGamePlayContext = () => {
  const context = React.useContext(GamePlayContext);
  if (!context) {
    throw new Error(
      'GamePlayContext must be used within a GamePlayContextProvider'
    );
  }
  const { game, submissions, draftSubmission, initialOptionPositions } =
    context;

  const latestGuess = getLatestGuess({ submissions });

  const lockedOptions = React.useMemo(() => {
    if (!latestGuess) {
      return [];
    }
    return gradeGuess(game, latestGuess)
      .filter(([, isCorrect]) => isCorrect)
      .map(([optionKey]) => optionKey);
  }, [latestGuess]);

  return {
    game: game,
    initialOptionPositions,
    latestGuess,
    draftSubmission,
    isOptionLocked: React.useCallback(
      (optionKey: string) => {
        return lockedOptions.includes(optionKey);
      },
      [lockedOptions]
    ),
    isSolved: React.useCallback(() => {
      return Boolean(latestGuess && isGuessCorrect(game, latestGuess));
    }, [latestGuess]),
  };
};
